import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Helmet from "react-helmet"
import SEO from "../helpers/seo"
import useWindowDimensions from "../helpers/utils"
import Img from "gatsby-image"

export default function Works({ data }) {
  const {
    seoTitle,
    seoKeywords,
    caseStudy,
    headline,
    workIntroTitle,
    workIntroDescriptions,
  } = data.contentfulWorkPage
  const [caseStudyListing, setCaseStudyListing] = useState(caseStudy)

  const [hover, setHover] = useState(false)
  const [selectedBgColor, setSelectedBgColor] = useState(null)
  const { width } = useWindowDimensions()

  const [currentFilter, setCurrentFilter] = useState("all")

  let r = caseStudy.map(a => a.tags)
  let merged = [].concat.apply([], r)
  var s = []
  for (var i = 0; i < merged.length; i++) {
    s.push(merged[i].toLowerCase())
  }
  s.sort()
  let unique = [...new Set(s)]

  const projectFilter = e => {
    let selected = e.target.getAttribute("projecttype")
    setCurrentFilter(selected)
    if (selected === "all") {
      setCaseStudyListing(filterWork(""))
    } else {
      setCaseStudyListing(filterWork(selected))
    }
  }

  const filterWork = keyword => {
    return caseStudy.filter((cv, index, arr) => {
      return cv.tags
        .toString()
        .toLowerCase()
        .includes(keyword)
    })
  }

  const mouseOut = () => {
    setHover(false)
  }

  const createMarkup = content => {
    return { __html: content }
  }

  useEffect(() => {
    var body = document.body,
    timer;    
    window.addEventListener('scroll', function() {
      clearTimeout(timer);
      if(!body.classList.contains('disable-hover')) {
        body.classList.add('disable-hover')
        setHover(false)
      }
      timer = setTimeout(function(){
        body.classList.remove('disable-hover')
        setHover(true) 
      },500);
    }, false);     
  })

  return (
    <React.Fragment>
      <Helmet>
        <body
          className={
            hover ? "work-listing-page header-transparent" : "work-listing-page"
          }
        />
      </Helmet>
      <SEO title={seoTitle} keywords={seoKeywords} />
      <div className="content-wrapper">
        <section className="intro">
          <div
            className="intro-title"
            dangerouslySetInnerHTML={createMarkup(
              headline.content[0].content[0].value
            )}
          />
          <div className="service">
            <div className="title">{workIntroTitle}</div>
            <p>{workIntroDescriptions.workIntroDescriptions}</p>
          </div>
        </section>

        <div className="project-filter">
          <ul>
            <li>
              <div
                className={currentFilter === "all" ? "active" : null}
                onClick={projectFilter}
                projecttype="all"
              >
                ALL
              </div>
            </li>
            {unique.map((val, k) => (
              <li key={k}>
                <div
                  className={currentFilter === val ? "active" : null}
                  onClick={projectFilter}
                  projecttype={val}
                >
                  {val.toUpperCase()}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div
          className="bg-color"
          style={
            hover
              ? { backgroundColor: selectedBgColor, opacity: 0.9 }
              : { opacity: 0 }
          }
        ></div>
        <div className="projects-wrapper grid">
          {caseStudyListing.map(post => {
            let dt = post.tags,
              sorted = []
            for (let i = 0; i < dt.length; i++) {
              sorted.push(dt[i].toLowerCase())
            }
            sorted.sort()
            return (
              <Link
                to={`/case-study/${post.slug}`}
                key={post.id}
                className="project-thumbnail grid-item"
                data-type={sorted}
                onMouseOver={() => {
                  setHover(true)
                  setSelectedBgColor(post.backgroundColor)    
                }}
                onMouseOut={mouseOut}
              >
                <Img fluid={post.heroImage.fluid} alt={post.heroImage.title} />
                <div
                  className="project-title all-caps"
                  style={width > 1100 ? { color: post.linkFontColor } : null}
                >
                  {post.title}
                </div>
                <div
                  className="link"
                  style={width > 1100 ? { color: post.linkFontColor } : null}
                >
                  View Case Study <span>→</span>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export const query = graphql`
  query CaseStudyPageQuery {
    contentfulWorkPage {
      headline {
        content {
          content {
            value
          }
        }
      }
      workIntroTitle
      workIntroDescriptions {
        workIntroDescriptions
      }
      seoTitle
      seoKeywords
      caseStudy {
        ... on ContentfulCaseStudyLongForm {
          id
          backgroundColor
          linkFontColor
          slug
          title
          tags
          heroImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
        }
        ... on ContentfulCaseStudyShortForm {
          id
          backgroundColor
          heroImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
          slug
          linkFontColor
          tags
          title
        }
      }
    }
  }
`
